import React, { useEffect, useRef, useState } from 'react';
import Style from './IndicatorsAndComparisonBarChart.module.scss'
import ReactEcharts from 'echarts-for-react'
import {Empty} from 'antd'
import AdditionalIndicators from './additionalIndicators'

export default function IndicatorsAndComparisonBarChart(props) {
    const { basicData, data } = props
    let [indicatorNameArr, setIndicatorNameArr] = useState([])//用于存储所有的指标名称
    let [gongsiArr, setGongsiArr] = useState([])
    let [additionalIndicatorsData, setAdditionalIndicatorsData] = useState(null)

    useEffect(() => {
        if (data && data.length > 0) {
            const indicators = new Set(); // 用于去重指标名称
            const yearArrSet = new Set(); // 用于去重年份数据
            const gongsiArrSet = new Set(); // 用于去重所有公司数据
            const company_map = {}
            let arr = []
            data.map(item => {
                let year_v = basicData.sector=='金融机构'?item['财务指标年度']:item['年份'],
                    company_v = basicData.sector=='金融机构'?item['中文全称']:item['企业名称']
                yearArrSet.add(year_v)
                gongsiArrSet.add(company_v)
                item.financialIndicator.forEach(i => {
                    if(!isNaN(i.indicatorValue)){
                        indicators.add(i.indicatorName)
                    }
                })
                if(company_map[company_v]) company_map[company_v].push(year_v)
                    else company_map[company_v] = [year_v]
            });
            Array.from(indicators).map(item => {
                arr.push({ type: item, xData: Array.from(yearArrSet) })
            })

            arr.map(item => {
                item["yData"] = getYdata(item,company_map)
            })
            setGongsiArr(Array.from(gongsiArrSet))
            setIndicatorNameArr(arr)
        }
        setAdditionalIndicatorsData(basicData.additionalIndicators)
    }, [data])
    const getYdata = (payload,company_map) => {
        let arr1 = []
        data.map(item => {
            let comany_v = basicData.sector=='金融机构'?item['中文全称']:item['企业名称'],
                year_v = basicData.sector=='金融机构'?item['财务指标年度']:item['年份']
            item.financialIndicator.forEach(i => {
                if (i.indicatorName === payload.type) {
                    if(!isNaN(i.indicatorValue)){
                        arr1.push({ 企业名称: comany_v, 年份: year_v, value: i.indicatorValue })
                    }
                }
            })
            
        });
        payload.xData.map(x=>{
            Object.keys(company_map).map(name=>{
                if(!company_map[name].includes(x)){
                    arr1.push({ 企业名称: name, 年份: x, value: '0' })
                }
            })
        })
        arr1 = arr1.sort(function (a, b) {
            let comany_v = basicData.sector=='金融机构'?'中文全称':'企业名称',
                year_v = basicData.sector=='金融机构'?'财务指标年度':'年份'
            let nameA = `${a[comany_v]}_${a[year_v]}`.toUpperCase(); // 转换为大写以忽略大小写
            let nameB = `${b[comany_v]}_${b[year_v]}`.toUpperCase();
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0; // 字符串相同
        })
        return arr1
    }
    const getSeries = (y) => {
        let arr = []
        gongsiArr.map(i => {
            arr.push({
                name: i,
                type: 'bar',
                emphasis: {
                    focus: 'series'
                },

                data: getSeriesData(y, i)

            },)

        })
        return arr
    }
    const getSeriesData = (data, i) => {
        let arr = []
        data.map(item => {
            if (item.企业名称 === i) {
                arr.push(item.value)
            }
        })
        return arr
    }
    const getOption = (item, xData, yData) => {
        return {
            tooltip: {
                confine: true,	//是否将 tooltip 框限制在图表的区域内
                trigger: 'axis',
                axisPointer: {
                    type: 'shadow'
                }
            },
            legend: {
                orient: 'horizontal', // 水平排列
                x: 'center', //可设定图例在左、右、居中
                y: 'top', //可设定图例在上、下、居中
                type: 'scroll',
            },
            grid: {
                left: '1%',     // 距离左边的距离，可以是像素或百分比
                right: '1%',    // 距离右边的距离
                bottom: '1%',   // 距离底部的距离
                top: '10%',     // 距离顶部的距离
                containLabel: true // 是否包含标签
            },
            xAxis: [
                {
                    type: 'category',
                    data: xData
                }
            ],
            yAxis: [
                {
                    type: 'value'
                }
            ],
            series: getSeries(yData)
        }
    }
    return (
        <>{additionalIndicatorsData &&
            <div className={Style.echartsBox}>
                <div className={Style.title}>额外指标</div>
                <AdditionalIndicators data={additionalIndicatorsData} />
            </div>
        }
            <div className={Style.echartsBox}>
                <div className={Style.title}>同业比较</div>
                <div className={`${Style.echartsList} ${indicatorNameArr.length == 0?Style.empty:''}`}>
                    {indicatorNameArr.length > 0
                        ?indicatorNameArr.map((item, index) => {
                            return <div key={index} className={Style.echartsItem}>
                                <div className={Style.echartsTitle}>{item.type}</div>
                                <ReactEcharts
                                    option={getOption(item, item.xData, item.yData)}
                                    notMerge={true}
                                    lazyUpdate={true}
                                    style={{ height: '400px' }}
                                />
                            </div>
                        })
                        :<Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
                    }  
                </div>
            </div>
        </>
    )
}
