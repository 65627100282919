import React, { useEffect, useState } from 'react';
import { Skeleton, Table, Tabs } from 'antd'
import BasicInfoCards from './BasicInfoCards.jsx'
import BusinessAndExternal from './BusinessAndExternal.jsx'
import SubjectCreditBarChart from './SubjectCreditBarChart.jsx'
import IndicatorsAndComparisonBarChart from './IndicatorsAndComparisonBarChart.jsx'
import {isArray, isObject} from '@/utils'
import Style from './customerDetailsOverviewTabPane.module.scss'
import UpPng from '../../../assets/icons/up.png'
import DownPng from '../../../assets/icons/down.png'
import AnalysisResults from '../../../assets/icons/analysisResults.png'
import Info from '../../../assets/icons/info.png'
import Adjustment from '../../../assets/icons/adjustment.png'
import FinancialStatements from './financialStatements'
import { useDispatch } from "react-redux";
import * as HomeActions from '../../../actions/home.js'

const CustomerDetailsOverviewTabPane = ({ data, skeletonLoading, id }) => {
  const dispatch = useDispatch();
  const [dataSource, setDataSource] = useState({})
  const getCompanyFinanceData = (params, callback) => dispatch(HomeActions.getCompanyFinance(params, callback));

  useEffect(() => {
    getCompanyFinance()
  }, [])

  const getCompanyFinance = () => {
    getCompanyFinanceData({id}, res=> {
      setDataSource(res)
    })
  }

  const getCompanyInfoData = (type, isFIType) => {
    const FI_LEFT = [
      {title:'评级基准', variable:'anchor'},
      {title:'业务状况', variable:'businessProfile'},
      {title:'资本与盈利性', variable:'capitalProfitability'},
      {title:'风险状况', variable:'riskProfile'},
      {title:'融资与流动性', variable:'financingLiquidity'},
      {title:'补充调整', variable:'additionalAdjustment'},
      {title:'个体信用质量', variable:'sacp'},
      // {title:'支持方的信用质量', variable:'supportIcrOrSacp'},
      {title:'外部支持重要性', variable:'externalSupportImportance'},
      {title:'外部影响', variable:'finalNotching'},
      {title:'未经调整集团信用质量（如适用）', variable:'unadjustedSacp'},
      {title:'控股公司的特有风险因素（如适用）', variable:'fhcRisk'},
    ], CORP_LEFT = [
      {title:'业务/经营状况', variable:'brp'},
      {title:'行业名称', variable:'industryName'},
      {title:'行业风险', variable:'industryRisk'},
      {title:'竞争地位', variable:'competitivePosition'},
      {title:'财务风险', variable:'frp'},
      {title:'评级基准', variable:'anchor'},
      {title:'个体信用状况', variable:'sacp'},
      {title:'外部影响', variable:'externalImpact'},
      {title:'主体信用评级结果', variable:'icr'},
    ]
    const renderArr = []
    if(type=='left'){
      (isFIType?FI_LEFT:CORP_LEFT).map(item => {
        if(data?.doc?.[item.variable]) renderArr.push({ top: data.doc[item.variable], center: item.title })
      })
    } else {
      if(data?.doc?.adjustmentItem) {
        Object.keys(data.doc.adjustmentItem).map(key=> renderArr.push({ top: data.doc.adjustmentItem[key], center: key }))
      }
    }
    return renderArr
  }
  // 关键指标数据处理
  const getKeyIndicatorscolumns = () => {
    let columns = [
      {
        title: ' ',
        dataIndex: 'name',
        key: 'name',
        width: 200
      },
      // {
      //   title: <div className='tableTitle'>2017年&nbsp;&nbsp; 较上年+20% <img src={UpPng} alt="" /></div>,
      //   dataIndex: '2017',
      //   key: '2017',
      // },
    ]
    if(data?.doc?.entityFinancialData){
      if (isArray(data?.doc?.entityFinancialData)&&data.doc.entityFinancialData.length > 0) {
        data.doc.entityFinancialData.map(item => {
          columns.push({
            title: item['年份'],
            dataIndex: item['年份'],
            key: item['年份'],
            width: 200
          })
        })
      } else if(isObject(data?.doc?.entityFinancialData)&&data.doc.entityFinancialData.financialIndicator.length>0){
        columns.push({
          title: data.doc.entityFinancialData['财务指标年度']||'-',
          dataIndex: data.doc.entityFinancialData['财务指标年度']||'-',
          key: data.doc.entityFinancialData['财务指标年度']||'-',
          width: 200
        })
      }
    }
    return columns
  }

  const getKeyIndicatorsTableData = () => {
    if (data?.doc?.entityFinancialData){
      if (isArray(data?.doc?.entityFinancialData)&&data.doc.entityFinancialData.length > 0) {
        const result = [];
        const indicators = new Set(); // 用于去重指标名称  
        // 遍历数据，收集所有独特的指标名称  
        data.doc.entityFinancialData.forEach(item => {
          item.financialIndicator.forEach(indicator => {
            indicators.add(indicator.indicatorName);
          });
        });
        // 转换为所需格式  
        indicators.forEach(indicatorName => {
          const entry = { name: indicatorName };
          data.doc.entityFinancialData.forEach(item => {
            const value = item.financialIndicator.find(i => i.indicatorName === indicatorName)?.indicatorValue;
            if (value) {
              entry[item.年份] = value;
            }
          });
          result.push(entry);
        });
        return result;
      } else if(isObject(data?.doc?.entityFinancialData)&&data.doc.entityFinancialData.financialIndicator.length>0){
        const result = [];
        data.doc.entityFinancialData.financialIndicator.map(item=>{
          if(item.indicatorValue) {
            result.push({name: item.indicatorName, [data.doc.entityFinancialData['财务指标年度']||'-']:item.indicatorValue})
          }
        })
        return result
      }
    }
  }
  const isFIType = data?.sector=='金融机构'
  const indicatorsAndComparisonBarChartData = isFIType
    ?(data?.doc?.peerFinancialData??[]).length==0?[]:[...(data?.doc?.peerFinancialData??[]),data?.doc?.entityFinancialData??{}]
    :(data?.doc?.peerEntityFinancialData??[]).length==0?[]:[...(data?.doc?.peerEntityFinancialData??[]),...(data?.doc?.entityFinancialData??[])]
  return (
    <div className={Style['customerDetailsOverviewTabPane']}>
      <div className={Style['basicInfo']}>
        <Skeleton loading={skeletonLoading} >
          <div className={Style['head']} >
            <div className={Style['title']}>基础信息</div>
            <div className={Style['updateTime']}>最近更新：{data?.reportDate}</div>
            <div className={Style['analysisResults']}>
              <div className={Style['results']}>非公开信用分析结果：<span>{data?.doc?.icrSub}</span></div>
              <div className={Style['imgBox']}><img src={AnalysisResults} alt="" /></div>
            </div>
          </div>
        </Skeleton>
        <div className={Style['cards']}>
          <div className={Style['cardsItem']} style={{ marginRight: "20px" }}>
            <Skeleton loading={skeletonLoading}>
              <div className={Style['positioningBox']}>
                <div>基础信息</div>
                <img src={Info} alt="" />
              </div>
              <BasicInfoCards list={getCompanyInfoData('left',isFIType)} modalTitle="基础信息" isFIType={isFIType} />
            </Skeleton>
          </div>
          { data?.sector!=='金融机构'
              ?<div className={Style['cardsItem']}>
                <Skeleton loading={skeletonLoading}>
                  <div className={Style['positioningBox']}>
                    <div>调整项明细</div>
                    <img src={Adjustment} alt="" />
                  </div>
                  <BasicInfoCards list={getCompanyInfoData('right',isFIType)} modalTitle="调整项明细" isFIType={isFIType} />
                </Skeleton>
              </div>
              :null
          }
        </div>
      </div>
      <Skeleton loading={skeletonLoading}>
        <BusinessAndExternal data={data} />
      </Skeleton>
      {/* 图表 */}
      <Skeleton loading={skeletonLoading}>
        <SubjectCreditBarChart basicData={data} />
      </Skeleton>
      <div className={Style['indicatorsTable']}>
        <div className={Style['title']}>财务分析</div>
        <Tabs defaultActiveKey={1} size="small"
          items={[
            {
              key: 1,
              label: '关键指标',
              children: <Table size="middle" bordered pagination={false}
                rowClassName={(record, index) => index % 2 === 0 ? Style["even"] : Style["odd"]}
                rowKey={(record, index) => index}
                columns={getKeyIndicatorscolumns()}
                dataSource={getKeyIndicatorsTableData()}
              />,
            },
            {
              key: 2,
              label: '财务报表',
              children: <FinancialStatements dataSource={dataSource} />,
            },

          ]} />

      </div>
      <IndicatorsAndComparisonBarChart basicData={data} data={indicatorsAndComparisonBarChartData} />
    </div>
  );
}

export default CustomerDetailsOverviewTabPane;