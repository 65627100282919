import service from "@/utils/request"

const credit = {
  // 获取企业详情
  getCompanyDetail(params) {
    return service.request({ url: "/company/getInfo", method: "get", params })
  },
  // 获取信用分析详情
  getCreditAnalysisDetail(params) {
    return service.request({ url: "/entityReport/getRecordDetail", method: "get", params })
  },
};

export default credit;