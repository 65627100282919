const modulesFiles = require.context('./modules', true, /\.js$/)

let initialState = {}, reducer = []
modulesFiles.keys().reduce((modules, modulePath) => {
  const value = modulesFiles(modulePath)
  value.reducer().map(_r => reducer.push(_r))
  initialState = Object.assign(initialState,value.initialState)
  return true
},{})
const reducers = (state = initialState, action) => {
  for(let _reducer in reducer) {
    if(action.type === reducer[_reducer].type) 
      return reducer[_reducer].state(state, action)
  }
  return state
}
export default reducers