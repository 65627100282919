import React, { useState, useEffect, useMemo } from 'react';
import Style from './index.module.scss';
import { Document, Page, pdfjs } from 'react-pdf';
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry';
pdfjs.GlobalWorkerOptions.workerSrc = pdfjsWorker;

const PdfContainer = (props) => {
  const [pdfBase64, setPdfBase64] = useState(null);
  const [numPages, setNumPages] = useState(null);

  const defaultProps = {
    url: null,
    width: 330,
    margin: {
      top: 0,
      bottom: 0,
      left: 0,
      right: 0
    }
  };

  const newProps = useMemo(() => ({ ...defaultProps, ...props }), [props]);

  useEffect(() => {
    const fetchPdf = async () => {
      try {
        const base64 = await getPdfAsBase64(newProps.url);
        setPdfBase64(base64);
      } catch (error) {
        console.error('Failed to fetch PDF:', error);
      }
    };

    if (newProps.url) {
      fetchPdf();
    }
  }, [newProps.url]);

  const getPdfAsBase64 = (url) => {
    if (!url) return Promise.reject(new Error('URL is required'));

    const index = url.lastIndexOf('/');
    const fileName = url.substring(index + 1);
    const baseUrl = process.env.NODE_ENV === 'development' ? '/pdf' : url.slice(0, index);

    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.open("GET", `${baseUrl}/${fileName}`, true);
      xhr.responseType = "blob";
      xhr.onload = () => {
        if (xhr.status === 200) {
          const blob = new Blob([xhr.response], { type: 'application/pdf;charset=utf-8' });
          const reader = new FileReader();
          reader.readAsDataURL(blob);
          reader.onload = () => resolve(reader.result);
        } else {
          reject(new Error(xhr.statusText));
        }
      };
      xhr.onerror = () => reject(new Error('Network error'));
      xhr.send();
    });
  };

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  return (
    <div className={Style.pdfContainer}>
      {pdfBase64 ? (
        <Document file={pdfBase64} onLoadSuccess={onDocumentLoadSuccess}>
          <div className={Style.PDFPageContainer}>
            {Array.from({ length: numPages }, (_, index) => (
              <Page
                pageNumber={index + 1}
                scale={3.2}
                key={`page_${index + 1}`}
                renderAnnotationLayer={false}
                renderTextLayer={false}
                width={newProps.width}
                className={Style.reactPdf}
              />
            ))}
          </div>
        </Document>
      ) : (
        <p className={Style.reactPdf}>Loading PDF...</p>
      )}
    </div>
  );
};

export default PdfContainer;
