import React, { useEffect, useState } from 'react'
import Style from './basicInfoCards.module.scss'
import { QuestionCircleOutlined } from '@ant-design/icons'
import UpPng from '../../../assets/icons/up.png'
import DownPng from '../../../assets/icons/down.png'
import more from '../../../assets/icons/more.png'
import CustomModal from '../../../components/CustomModal'

export default function BasicInfoCards(props) {
  const { list, modalTitle, isFIType } = props
  const [data, setData] = useState([])
  const [showMore, setShowMore] = useState(false)
  const [showModal, setshowModal] = useState(false)

  useEffect(() => {
    if (list && list.length > 8 && !isFIType) {
      setData(list.slice(0, 8))
      setShowMore(true)
    } else {
      setData(list)
      setShowMore(false)
    }
  }, [list])
  const moreClick = () => {
    setshowModal(true)
  }
  const handleCancel = () => {
    setshowModal(false);
  };
  const handleOk = () => {
    handleCancel()
  };

  return (
    <div className={Style['basicInfoCards']}>
      <div className={Style['listItem']}></div>
      {data.map((item, index) => {
        return <div className={Style['listItem']} key={index}>
          <div className={Style['top']}>{item.center}</div>
          <div className={Style['center']}>{item.top} </div>
          {item.bottom ?
            <div className={Style['bottom']}>{item.bottom}{item.bottomNum} <img src={item.bottomStatus ? UpPng : DownPng} alt="" /></div>
            :
            <div style={{ width: "10px", height: "10px" }}></div>
          }
        </div>
      })}
      {showMore &&
        <div className={Style.moreBox} onClick={moreClick}>
          <img className={Style.top} src={more} alt="" />
          <div className={Style.text}>查看更多</div>
        </div>
      }
      {showModal &&
        <CustomModal width={600} isModalOpen={showModal} modalTitle={modalTitle} handleCancel={handleCancel} handleOk={handleOk}>
          <div style={{ display: "flex", flexWrap: "wrap", }}>
            {list.map((item, index) => {
              return <div key={index} style={{ width: "50%", height: "40px", display: "flex", justifyContent: "space-between", alignItems: "center", padding: "0 40px", boxSizing: "border-box" }}>
                <div style={{ fontWeight: "400", fontSize: "14px", color: "rgba(0,0,0,0.45)" }}>{item.center}</div>
                <div style={{ fontWeight: "500", fontSize: "14px", color: "#000000" }}>{item.top}</div>
              </div>
            })}
          </div>
        </CustomModal>
      }
    </div >
  )
}
