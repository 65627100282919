import React, { useEffect, useState } from "react";
import file_icon from "../../assets/icons/file_icon.png";
import company_icon from "../../assets/icons/company_icon.png";
import { Space, Tag, Button, Tabs, Skeleton, message } from "antd";
import CustomerDetailsOverviewTabPane from "./comps/customerDetailsOverviewTabPane";
import { useNavigate } from "react-router-dom";
import { LeftOutlined } from "@ant-design/icons";
import { fileLinkToStreamDownload } from "../../utils";
import Style from "./index.module.scss";
import api from '@/api'
import TableTitle from "../../components/tableTitle";
import CustomModal from "@/components/CustomModal";
import { useSearchParams } from "react-router-dom";
import PdfPreview from "./comps/pdfPreview";

const QueryView = () => {
  const navigate = useNavigate(); // 路由跳转
  const [skeletonLoading, setSkeletonLoading] = useState(true)
  const [tabActive, setTabActive] = useState('1')
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [searchParams] = useSearchParams(); // 获取查询参数
  const id = searchParams.get("id"); // 获取名为 'url' 的查询参数
  const [companyInfo, setCompanyInfo] = useState({}); // 公司信息
  const [customerDetailsOverviewTabPanedata, setCustomerDetailsOverviewTabPaneData] = useState({})

  useEffect(() => {
    window.scrollTo(0, 0);
    setTimeout(() => { setSkeletonLoading(false) }, 1000)
  }, []);

  useEffect(() => {
    if (!id) return;
    getInfoData(id);
  }, [id]);

  const getInfoData = async (id) => {
    const companyDetail = await api.getCompanyDetail({id})
    if(companyDetail.code==200){
      setCompanyInfo(companyDetail.data)
      const result = await api.getCreditAnalysisDetail({companyId:id})
      if(result.code==200){
        setCustomerDetailsOverviewTabPaneData(result.data)
      } else message.error(result.data.message)
    }
  };
  const downLoad = () => {
    return setIsModalOpen(true)
    // fileLinkToStreamDownload(companyInfo.reportLink);
  };
  const onTabClick = (key) => {
    if(key=='2') return setIsModalOpen(true)
    setTabActive(key)
  }
  const onChange = (key) => {
    console.log(key);
  };
  const items = [
    {
      key: "1",
      label: "网页版",
      children: <CustomerDetailsOverviewTabPane data={customerDetailsOverviewTabPanedata} id={id} skeletonLoading={skeletonLoading} />,
    },
    {
      key: "2",
      label: "PDF版",
      children: <PdfPreview reportLink={companyInfo.reportLink}></PdfPreview>,
    },
  ];
  return (
    <div className={Style["pdf-container"]}>
      <div className={Style["main-container"]}>
        <TableTitle backdropFilter="3px">
          <div className={Style["title-container"]}>
            <Space className={Style["title-description"]}>
              <img src={file_icon} alt="bar_icon" />
              <span>标普非公开信用分析和信用评级分布</span>
            </Space>
            <Button
              onClick={() => navigate(-1)}
              className={Style["back-btn"]}
              size="normal"
              icon={<LeftOutlined />}
            >
              {" "}
              返回列表
            </Button>
          </div>
        </TableTitle>
        <div className={Style["header"]}>
          <div className={Style["headTitle"]}>
            <Skeleton loading={skeletonLoading} >
              <img className={Style["logoBox"]} src={company_icon} alt="" />
              <div className={Style["nameBox"]}>
                <div className={Style["name"]}>
                  <div className={Style["text"]}>{companyInfo.name}</div>
                  <Tag style={{ border: "none", height: "20px" }} color="processing">
                    {companyInfo.industries}
                  </Tag>
                </div>
                <div className={Style["code"]}>
                  <Tag style={{ border: "none" }} color="success">
                    {companyInfo.quality}
                  </Tag>
                  <div className={Style["text"]}>
                    统一社会信用代码: {companyInfo.uscc}
                  </div>
                </div>
              </div>
            </Skeleton>
          </div>
          <Skeleton loading={skeletonLoading}>
            <Button onClick={downLoad}>下载报告</Button>
          </Skeleton>
        </div>
        <div style={{ backgroundColor: "#fff", padding: "10px 20px 20px 20px " }}>
          <Tabs activeKey={tabActive} items={items} onChange={onChange} onTabClick={onTabClick}/>
        </div>
      </div>
      <CustomModal handleCancel={()=> setIsModalOpen(false)} handleOk={()=> setIsModalOpen(false)} 
        isModalOpen={isModalOpen} wechatQRCode={true} />
    </div>
  );
};

export default QueryView;
