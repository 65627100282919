import React, { useEffect, useState, useRef } from 'react'
import { Tag, Tooltip } from 'antd'
import s from './BusinessAndExternal.module.scss'
import { encode, decode } from 'js-base64'

function TextWithReadMore({ text, item, index }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const [showButton, setShowButton] = useState(false);
  const textRef = useRef(null);

  useEffect(() => {
    if (textRef.current) {
      const lineHeight = parseFloat(window.getComputedStyle(textRef.current).lineHeight);//获取每一行的行高
      const scrollHeight = textRef.current.scrollHeight;//获取文本实际的总高度
      const visibleLines = Math.ceil(scrollHeight / lineHeight);
      setShowButton(visibleLines > 3);
    }
  }, [text]);
  const turnNode = (data) => {
    const text = JSON.stringify(data).replace(/\\n/g, "<br>").replace(/^["']|["']$/g, '')
    let texthtml = text.split("<br>").map(item => {
      return <span style={{ textIndent: "2em" }}>{(item)}<br /></span>
    })
    return texthtml
  }

  return (
    <div className={s.content}>
      <div
        ref={textRef}
        className={s.text}
        dangerouslySetInnerHTML={{ __html: item.contentBase64 }}
      ></div>
      {showButton && (
        <div className={s.tootipBox}>
          <Tooltip key={index} title={turnNode(item.contentBase64)} placement={index % 2 === 0 ? "right" : "left"} overlayInnerStyle={{ width: "700px", height: "auto" }}>
            <a >查看更多</a>
          </Tooltip>
        </div>
      )}
    </div>
  );
}


export default function BusinessAndExternal(props) {
  const { data } = props
  let [analyticalTextData, setAnalyticalTextData] = useState([])

  useEffect(() => {
    const analyticalText = data?.doc?.analyticalText??[]
    let arr = []
    if (analyticalText && analyticalText.length > 0) {
      analyticalText.map(item => {
        if (item.contentInBase64) {
          let _str = decode(item.contentInBase64)
          _str = _str.replace(/\r\n/g, '\n')
          if(_str.slice(0,1)=="\n"){
            _str = _str.replace(/\n/, '')
          }
          arr.push({title: item.title, contentBase64: _str})
        }
      })
    }
    setAnalyticalTextData(arr)
  }, [data])


  return (
    <div className={s.BusinessAndExternal}>
      <div className={s.cardsList}>
        {analyticalTextData.map((item, index) => {
          return  <div className={s.businessBox} key={index}>
            <div className={s.head}>
              <div className={s.title}>{item.title}</div>
            </div>
            <div className={s.content} >
              <TextWithReadMore text={item.contentBase64} item={item} index={index} />
            </div>
          </div>
        })}
      </div>
    </div>
  )
}
