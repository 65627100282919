const modulesFiles = require.context('./modules', true, /\.js$/)

let config = {}

modulesFiles.keys().reduce((modules, modulePath) => {
  const value = modulesFiles(modulePath)
  config = Object.assign(config,value.default)
  return true
},{})

export default config