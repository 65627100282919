import React from 'react';
import Style from './index.module.scss'
function TableTitle(props) {
  const style = {
    filter: `blur(${props.blur})`,
    backdropFilter: `blur(${props.backdropFilter})`,
    backgroundColor: props.bgc ? props.bgc : 'transparent',
    border: props.border ? props.border : 'none'
  }
  return (
    <div className={Style['tableContainer']} style={style}>
      {props.children}
    </div>
  )
}

export default TableTitle;