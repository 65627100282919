import sheetData from './data.json';

let titleEdit = Object.keys(sheetData["表格标题"])

//计算colums总数(废弃)
export const automaticCalc = [
  "一、流动资产",
  "二、非流动资产",
  "三、流动负债",
  "四、非流动负债",
  "五、股东权益",

  "一、营业总收入",
  "二、营业总成本",
  "三、营业利润（亏损以“-”号填列）",
  "四、利润总额（亏损总额以“-”号填列）",
  "五、净利润（净亏损以“-”号填列）",

  "一、经营活动产生的现金流量",
  "二、投资活动产生的现金流量",
  "三、筹资活动产生的现金流",
  "五、现金及现金等价物净增加额",
  "七、现金流量表附表"
]

//修改期初期末
export const automaticTime = [
  "六、其他综合收益的税后净额",
  "七、综合收益总额",
  "八、每股收益"
]

//必填字段
export const requireField = {
  "bizinco": '营业收入',  //利润表  营业收入
  "bizcost": '营业成本', //... 营业成本
  "salesexpe": '消费费用',  //... 消费费用
  "manaexpe": '管理费用',  //... 管理费用
  "finexpe": '财务费用',  //... 财务费用
  "curfds": '货币资金',   //资产负债表  货币资金
  "notesrece": '应收票据',   //...  应收票据
  "accorece": '应收账款',   //...  应收账款
  "recfinanc": '应收款项融资',   //...  应收款项融资
  "inve": '存货',   //...  存货
  "contractasset": '合同资产',   //...  合同资产
  //"totcurrasset": '流动资产合计',   //...  流动资产合计
  "intaasset": '无形资产',  //... "无形资产": ,
  //"totalnoncassets": '非流动资产合计',  //..."非流动资产合计": ,
  //"totasset": '资产合计', //..."资产合计"
  "shorttermborr": '短期借款',  //... "短期借款": ,
  "notespaya": '应付票据',  //... "应付票据": ,
  "accopaya": '应付账款', //... "应付账款": ,
  "duenoncliab": '一年内到期的非流动负债',  //... "一年内到期的非流动负债": ,
  "longborr": '一年内到期的非流动负债', //... "长期借款": ,
  "bdspaya": '应付债券',  //... "应付债券": ,
  //"totalnoncliab": '非流动负债合计',  //... "非流动负债合计": ,
  //"totliab": '负债合计',  //... "负债合计": ,
  "minysharrigh": '少数股东权益', //... "少数股东权益": ,
  //"righaggr": '股东权益合计', //... "股东权益合计": ,
  //"totliabsharequi": '负债与股东权益合计',  //... "负债与股东权益合计"
  "laborgetcash": '销售商品、提供劳务收到的现金', //现金流量表 "销售商品、提供劳务收到的现金": ,
  "labopayc": '购买商品、接受劳务支付的现金', //... "购买商品、接受劳务支付的现金": ,
  //"mananetr": '经营活动产生的现金流量净额', //... "经营活动产生的现金流量净额"
  "fixedassetnetc": '处置固定资产、无形资产和其他长期资产而所收到的现金净额', //... "处置固定资产、无形资产和其他长期资产而所收到的现金净额": ,
  "diviprofpaycash": '分配股利、利润或偿付利息所支付的现金',  //... "分配股利、利润或偿付利息所支付的现金": ,
}

//标题需要传输的字段
export const requireTitleField = {
  "一、营业总收入": "biztotinco",
  "二、营业总成本": "biztotcost",
  "三、营业利润（亏损以“-”号填列）": "perprofit",
  "四、利润总额（亏损总额以“-”号填列）": "totprofit",
  "五、净利润（净亏损以“-”号填列）": "netprofit",
  "五、现金及现金等价物净增加额": "cashnetr",
  "六、期末现金及现金等价物余额": "finalcashbala"
}

//需要缩进
export const indentation = [
  "inteincoopcost",   //利润表  利息收入
  "assoinveprof",     //...  对联营企业和合营企业的投资收益
  "amortizcostassetssapi",    //...  以摊余成本计量的金融资产终止确认收益
  "noncassetsdisl",   //"非流动资产处置损失"
  "interestexpense",  //利息费用
  "prest",    //资产负债表  优先股
  "perbond",  //...  永续债
  "totcurrasset",   //流动资产合计
  "totalnoncassets",  //非流动资产合计
  "totalcurrliab",  // "流动负债合计"
  "totalnoncliab",  // "非流动负债合计",
  //"totliab",  // "负债合计"
  "subspaydivid",    //现金流量表   子公司支付给少数股东的股利、利润
  "subsrececash", //现金流量表  子公司吸收少数股东投资收到的现金
  "subspaydivid", //... 子公司支付给少数股东的股利、利润
  "bizcashinfl",  //"经营活动现金流入小计",
  "bizcashoutf",  // "经营活动现金流出小计",
  "invcashinfl", //"投资活动现金流入小计",
  "invcashoutf", //"投资活动现金流出小计",
  "fincashinfl", //"筹资活动现金流入小计",
  "fincashoutf", //"筹资活动现金流出小计",
]

//双倍缩进
export const doubleIndent = [
  "mananetr",   // "经营活动产生的现金流量净额"
  "invnetcashflow", //"投资活动产生的现金流量净额"
  "finnetcflow", //"筹资活动产生的现金流量净额"
]

//反向缩进
export const diffIndent = [
  "totliabsharequi",  // "负债与股东权益合计"
]

//需要加粗
export const fieldBold = [
  //资产负债表
  "totcurrasset",   //流动资产合计
  "totalnoncassets",  //非流动资产合计
  "totasset",   //资产合计
  "totalcurrliab",  // "流动负债合计"
  "totalnoncliab",  // "非流动负债合计",
  "totliab",  // "负债合计"
  //利润表
  "一、营业总收入",
  "二、营业总成本",
  "bizcost",  //"营业成本",
  "三、营业利润（亏损以“-”号填列）",
  "四、利润总额（亏损总额以“-”号填列）",
  "五、净利润（净亏损以“-”号填列）",
  "othercompinco",  //"六、其他综合收益的税后净额",
  "compincoamt",  //"七、综合收益总额",
  "eps", //"八、每股收益",
  "righaggr", // "股东权益合计",
  "totliabsharequi",  // "负债与股东权益合计"
  //现金流量表
  "一、经营活动产生的现金流量",
  "bizcashinfl",  //"经营活动现金流入小计",
  "bizcashoutf",  // "经营活动现金流出小计",
  "mananetr",   // "经营活动产生的现金流量净额"
  "二、投资活动产生的现金流量",
  "invcashinfl", //"投资活动现金流入小计",
  "invcashoutf", //"投资活动现金流出小计",
  "invnetcashflow", //"投资活动产生的现金流量净额"
  "三、筹资活动产生的现金流",
  "fincashinfl", //"筹资活动现金流入小计",
  "fincashoutf", //"筹资活动现金流出小计",
  "finnetcflow", //"筹资活动产生的现金流量净额"
  "chgexchgchgs",
  "五、现金及现金等价物净增加额",
  "六、期末现金及现金等价物余额",
  "七、现金流量表附表"
]

//财务分析 %值 * 100
export const financialFieldTake = [
  "mainBusinessRatio",
  "preTaxProfitsRatio",
  "totalAssetReturn",
  "debtAssetRatio",
  "currentDebtEquityRatio",
  "capitalizationRatio",
  "netDebtRatio",
  "incomeGrowthRate",
  "profitsGrowthRate",
  "cashGrowthRate"
]

//需要自动计算的值，计算方式累加
export const automaticCalcData = {
  "一、营业总收入": ['bizinco', 'inteinco', 'earnprem', 'pouninco','biztotincoOther'],
  "二、营业总成本": ['bizcost', 'inteexpe', 'pounexpe', 'surrgold', 'compnetexpe', 'contress', 'polidiviexpe', 'reinexpe', 'biztax', 'salesexpe', 'manaexpe', 'finexpe','biztotcostOther'],
  "营业总成本_营业利润": ['otherinco', 'inveinco', 'valuechgloss', 'exchggain', 'netexpohedinc', 'creditimplosse', 'asseimpaloss', 'assetsdislinco','nominIncoOther'],
  "三、营业利润（亏损以“-”号填列）": ['valuechgloss', 'inveinco', 'exchggain', 'assetsdislinco', 'netexpohedinc', 'otherinco','nominIncoOther'],

  "一、流动资产": ['curfds', 'tradfinasset', 'settresedepo', 'plac', 'derifinaasset', 'notesrece', 'accorece', 'recfinanc', 'prep', 'premrece', 'reinrece', 'reincontrese', 'interece', 'dividrece', 'otherrece', 'purcresaasset', 'inve', 'contractasset', 'accheldfors', 'expinoncurrasset', 'prepexpe', 'othercurrasse', 'subsrece', 'margrece', 'intelrece', 'unseg', 'expotaxrebarece','currassetOther'],
  "二、非流动资产": ['lendandloan', 'othdebtinvest', 'fairvalueassets', 'amortizcostassets', 'avaisellasse', 'holdinvedue', 'longrece', 'equiinve', 'othequininvest', 'othernoncfinasse', 'inveprop', 'fixedassenet', 'consprog', 'engimate', 'fixedasseclea', 'prodasse', 'hydrasset', 'ruseassets', 'intaasset', 'deveexpe', 'goodwill', 'logprepexpe', 'defetaxasset', 'othernoncasse', 'otherlonginve','noncassetsOther'],

  "三、流动负债": ['shorttermborr', 'tradfinliab', 'cenbankborr', 'deposit', 'fdsborr', 'deriliab', 'notespaya', 'accopaya', 'advapaym', 'sellrepasse', 'copepoun', 'copeworkersal', 'taxespaya', 'intepaya', 'divipaya', 'otherpay', 'copewithreinrece', 'insucontrese', 'actitradsecu', 'actiundesecu', 'liabheldfors', 'duenoncliab', 'defereve', 'shorttermbdspaya', 'othercurreliabi', 'contractliab', 'accrexpe', 'intelpay', 'margrequ', 'warliabrese','curreliabiOther'],
  "四、非流动负债": ['longborr', 'longdefeinco','bdspaya', 'leaseliab', 'longpaya', 'lcopeworkersal', 'specpaya', 'expenoncliab', 'defeincotaxliab', 'othernoncliabi', 'unreinveloss', 'topaycashdivi', 'curtrandiff','noncliabiOther'],
  "五、股东权益": ['paidincapi', 'othequin', 'capisurp', 'ocl', 'specrese', 'rese', 'generiskrese', 'undiprof','paresharrighOther'],

  "经营活动现金流入小计": ['laborgetcash', 'deponetr', 'bankloannetincr', 'fininstnetr', 'inspremcash', 'insnetc', 'savinetr', 'charintecash', 'fdsborrnetr', 'repnetincr', 'taxrefd', 'receotherbizcash'],
  "经营活动现金流出小计": ['labopayc', 'loansnetr', 'tradepaymnetr', 'paycompgold', 'payintecash', 'paydivicash', 'payworkcash', 'paytax', 'payacticash'],

  "投资活动现金流入小计": ['withinvgetcash', 'inveretugetcash', 'fixedassetnetc', 'subsnetc', 'receinvcash'],
  "投资活动现金流出小计": ['acquassetcash', 'invpayc', 'loannetr', 'subspaynetcash', 'payinvecash'],

  "筹资活动现金流入小计": ["invrececash", "recefromloan", "issbdrececash", "recefincash"],
  "筹资活动现金流出小计": ["debtpaycash", "diviprofpaycash", "finrelacash"]
}

//需要自动计算的值
export const automaticCalcAttributes = [
  "totcurrasset", "totcurrasset", "totalnoncassets", "totasset", "totalcurrliab", "totalnoncliab", "totliab", "paresharrigh", "righaggr", "totliabsharequi", "bizcashinfl", "bizcashoutf", "mananetr", "invcashinfl", "invcashoutf", "invnetcashflow",
  "fincashinfl", "fincashoutf", "finnetcflow", "finalcashbala", "cashneti"
]

export const tableDataSwitch = (newData, type) => {
  const begin = {}
  const end = {}
  newData.forEach(item => {
    if (titleEdit.includes(item.sort)) {
      begin[item.key] = item.begin
      end[item.key] = item.end
    }
    item.children.forEach(i => {
      begin[i.key] = i.begin
      end[i.key] = i.end
    })
  })
  //判断字段全空则为空，否则计算
  const fieldsIsNull = (title, _data) => {
    if (automaticCalcData[title].every(item => _data[item] == "")) {
      return ""
    } else {
      return automaticCalcData[title].map(item => Number(_data[item])).reduce(((total, cur) => total + cur), 0).toFixed(2)
    }
  }
  //计算两数之间操作   action: 'add': 加  //  'subtract': 减
  const actionTwoField = (num1, num2, action) => {
    if (num1 === "" && num2 === "") {
      return ""
    } else if (action === 'subtract') {
      return (Number(num1) - Number(num2)).toFixed(2)
    } else if (action === 'add') {
      return (Number(num1) + Number(num2)).toFixed(2)
    }
  }

  if (type === '利润表') {
    let businessIncome = [] //一、营业总收入
    let businessCost = [] //二、营业总成本
    let otherProfitOperating = [] //二、营业利润需要额外的加  计算
    let operatingProfit = []  //三、营业利润（亏损以“-”号填列）
    let totalProfit = []  //四、利润总额（亏损总额以“-”号填列）
    let netProfit = []  //五、净利润（净亏损以“-”号填列）

    businessIncome[0] = fieldsIsNull("一、营业总收入", begin)
    businessIncome[1] = fieldsIsNull("一、营业总收入", end)
    
    businessCost[0] = fieldsIsNull("二、营业总成本", begin)
    businessCost[1] = fieldsIsNull("二、营业总成本", end)

    otherProfitOperating[0] = fieldsIsNull("营业总成本_营业利润", begin)
    otherProfitOperating[1] = fieldsIsNull("营业总成本_营业利润", end)

    operatingProfit[0] = actionTwoField(
      actionTwoField(businessIncome[0], businessCost[0], 'subtract'),
      otherProfitOperating[0],
      'add'
    )
    operatingProfit[1] = actionTwoField(
      actionTwoField(businessIncome[1], businessCost[1], 'subtract'),
      otherProfitOperating[1],
      'add'
    )

    // "nonoreve": "加：营业外收入",
    // "nonoexpe": "减：营业外支出",
    // totalProfit[0] = operatingProfit + nonoreve - nonoexpe
    totalProfit[0] = actionTwoField(
      actionTwoField(operatingProfit[0], begin["nonoreve"], 'add'),
      begin["nonoexpe"],
      'subtract'
    )
    // (Number(operatingProfit[0]) + Number(begin["nonoreve"]) - Number(begin["nonoexpe"])).toFixed(2)
    totalProfit[1] = actionTwoField(
      actionTwoField(operatingProfit[1], end["nonoreve"], 'add'),
      end["nonoexpe"],
      'subtract'
    )
    // (Number(operatingProfit[0]) + Number(end["nonoreve"]) - Number(end["nonoexpe"])).toFixed(2)

    //"incotaxexpe": "减：所得税费用",
    netProfit[0] = actionTwoField(totalProfit[0], begin["incotaxexpe"], 'subtract')
    netProfit[1] = actionTwoField(totalProfit[1], end["incotaxexpe"], 'subtract')

    let obj = {
      "一、营业总收入": businessIncome,
      "二、营业总成本": businessCost,
      "三、营业利润（亏损以“-”号填列）": operatingProfit,
      "四、利润总额（亏损总额以“-”号填列）": totalProfit,
      "五、净利润（净亏损以“-”号填列）": netProfit
    }
    const autoCalcArray = Object.keys(obj)

    newData.map(item => {
      if (autoCalcArray.includes(item.key)) {
        item.begin = obj[item.key][0]
        item.end = obj[item.key][1]
      }
    })

  }
  if (type === '资产表') {
    let totalCurrentAssets = [] //流动资产合计
    let totalNonCurrentAssets = [] //非流动资产合计

    totalCurrentAssets[0] = fieldsIsNull("一、流动资产", begin)
    //automaticCalcData["一、流动资产"].map(item => Number(begin[item])).reduce(((total, cur)=> total + cur), 0).toFixed(2)
    totalCurrentAssets[1] = fieldsIsNull("一、流动资产", end)
    //automaticCalcData["一、流动资产"].map(item => Number(end[item])).reduce(((total, cur)=> total + cur), 0).toFixed(2)

    totalNonCurrentAssets[0] = fieldsIsNull("二、非流动资产", begin)
    //automaticCalcData["二、非流动资产"].map(item => Number(begin[item])).reduce(((total, cur)=> total + cur), 0).toFixed(2)
    totalNonCurrentAssets[1] = fieldsIsNull("二、非流动资产", end)
    //automaticCalcData["二、非流动资产"].map(item => Number(end[item])).reduce(((total, cur)=> total + cur), 0).toFixed(2)

    //"totcurrasset": "流动资产合计"
    // "totalnoncassets": "非流动资产合计",
    // "totasset": "资产合计"
    newData.map(item => {
      if (item.key === '一、流动资产') {
        // item.begin = totalCurrentAssets[0]
        // item.end = totalCurrentAssets[1]
        item.children.map(i => {
          if (i.key === 'totcurrasset') {
            i.begin = totalCurrentAssets[0]
            i.end = totalCurrentAssets[1]
          }
        })
      }
      if (item.key === '二、非流动资产') {
        // item.begin = totalNonCurrentAssets[0]
        // item.end = totalNonCurrentAssets[1]
        item.children.map(i => {
          if (i.key === 'totalnoncassets') {
            i.begin = totalNonCurrentAssets[0]
            i.end = totalNonCurrentAssets[1]
          }
          if (i.key === 'totasset') {
            i.begin = actionTwoField(totalCurrentAssets[0], totalNonCurrentAssets[0], 'add')
            // (Number(totalCurrentAssets[0]) + Number(totalNonCurrentAssets[0])).toFixed(2)
            i.end = actionTwoField(totalCurrentAssets[1], totalNonCurrentAssets[1], 'add')
            //(Number(totalCurrentAssets[1]) + Number(totalNonCurrentAssets[1])).toFixed(2)
          }
        })
      }
    })
  }
  if (type === '负债表') {
    let totalCurrentLiabilities = [] //流动负债合计
    let totalNonCurrentLiabilities = [] //非流动负债合计
    let totalLiab = []  //负债合计
    let totalParesharrigh = []  //归属母公司所有者权益
    let totalRighaggr = []  //股东权益合计
    let totliabsharequiData = []  //负债与股东权益合计

    totalCurrentLiabilities[0] = fieldsIsNull("三、流动负债", begin)
    //automaticCalcData["三、流动负债"].map(item => Number(begin[item])).reduce(((total, cur)=> total + cur), 0).toFixed(2)
    totalCurrentLiabilities[1] = fieldsIsNull("三、流动负债", end)
    //automaticCalcData["三、流动负债"].map(item => Number(end[item])).reduce(((total, cur)=> total + cur), 0).toFixed(2)

    totalNonCurrentLiabilities[0] = fieldsIsNull("四、非流动负债", begin)
    //automaticCalcData["四、非流动负债"].map(item => Number(begin[item])).reduce(((total, cur)=> total + cur), 0).toFixed(2)
    totalNonCurrentLiabilities[1] = fieldsIsNull("四、非流动负债", end)
    //automaticCalcData["四、非流动负债"].map(item => Number(end[item])).reduce(((total, cur)=> total + cur), 0).toFixed(2)

    totalLiab[0] = actionTwoField(totalCurrentLiabilities[0], totalNonCurrentLiabilities[0], 'add')
    //(Number(totalCurrentLiabilities[0]) + Number(totalNonCurrentLiabilities[0])).toFixed(2)
    totalLiab[1] = actionTwoField(totalCurrentLiabilities[1], totalNonCurrentLiabilities[1], 'add')
    //(Number(totalCurrentLiabilities[1]) + Number(totalNonCurrentLiabilities[1])).toFixed(2)

    //"treastk": "减：库存股",
    totalParesharrigh[0] = actionTwoField(
      fieldsIsNull("五、股东权益", begin),
      begin["treastk"],
      'subtract'
    )
    //(automaticCalcData["五、股东权益"].map(item => Number(begin[item])).reduce(((total, cur)=> total + cur), 0) - Number(begin["treastk"])).toFixed(2)
    totalParesharrigh[1] = actionTwoField(
      fieldsIsNull("五、股东权益", end),
      end["treastk"],
      'subtract'
    )
    //(automaticCalcData["五、股东权益"].map(item => Number(end[item])).reduce(((total, cur)=> total + cur), 0) - Number(end["treastk"])).toFixed(2)

    //"minysharrigh": "少数股东权益",
    totalRighaggr[0] = actionTwoField(totalParesharrigh[0], begin["minysharrigh"], 'add')
    //(Number(totalParesharrigh[0]) + Number(begin["minysharrigh"])).toFixed(2)
    totalRighaggr[1] = actionTwoField(totalParesharrigh[1], end["minysharrigh"], 'add')
    //(Number(totalParesharrigh[1]) + Number(end["minysharrigh"])).toFixed(2)

    totliabsharequiData[0] = actionTwoField(totalLiab[0], totalRighaggr[0], 'add')
    //(Number(totalLiab[0]) + Number(totalRighaggr[0])).toFixed(2)
    totliabsharequiData[1] = actionTwoField(totalLiab[1], totalRighaggr[1], 'add')
    //(Number(totalLiab[1]) + Number(totalRighaggr[1])).toFixed(2)

    //"totalcurrliab": "流动负债合计"
    newData.map(item => {
      if (item.key === '一、流动负债') {
        // item.begin = totalCurrentLiabilities[0]
        // item.end = totalCurrentLiabilities[1]
        item.children.map(i => {
          if (i.key === 'totalcurrliab') {
            i.begin = totalCurrentLiabilities[0]
            i.end = totalCurrentLiabilities[1]
          }
        })
      }
      if (item.key === '二、非流动负债') {
        // item.begin = totalNonCurrentLiabilities[0]
        // item.end = totalNonCurrentLiabilities[1]
        item.children.map(i => {
          if (i.key === 'totalnoncliab') {
            i.begin = totalNonCurrentLiabilities[0]
            i.end = totalNonCurrentLiabilities[1]
          }
          if (i.key === 'totliab') {
            i.begin = totalLiab[0]
            i.end = totalLiab[1]
          }
        })
      }
      if (item.key === '三、股东权益') {
        // item.begin = totalRighaggr[0]
        // item.end = totalRighaggr[1]
        item.children.map(i => {
          if (i.key === 'paresharrigh') {
            i.begin = totalParesharrigh[0]
            i.end = totalParesharrigh[1]
          }
          if (i.key === 'righaggr') {
            i.begin = totalRighaggr[0]
            i.end = totalRighaggr[1]
          }
          if (i.key === 'totliabsharequi') {
            i.begin = totliabsharequiData[0]
            i.end = totliabsharequiData[1]
          }
        })
      }
    })
  }
  if (type === '现金流量表') {
    let bizcashinflData = []  //"经营活动现金流入小计"
    let bizcashoutfData = []  //"经营活动现金流出小计"
    let mananetrData = []  //"经营活动产生的现金流量净额"

    bizcashinflData[0] = fieldsIsNull("经营活动现金流入小计", begin)
    //automaticCalcData["经营活动现金流入小计"].map(item => Number(begin[item])).reduce(((total, cur)=> total + cur), 0).toFixed(2)
    bizcashinflData[1] = fieldsIsNull("经营活动现金流入小计", end)
    //automaticCalcData["经营活动现金流入小计"].map(item => Number(end[item])).reduce(((total, cur)=> total + cur), 0).toFixed(2)

    bizcashoutfData[0] = fieldsIsNull("经营活动现金流出小计", begin)
    //automaticCalcData["经营活动现金流出小计"].map(item => Number(begin[item])).reduce(((total, cur)=> total + cur), 0).toFixed(2)
    bizcashoutfData[1] = fieldsIsNull("经营活动现金流出小计", end)
    //automaticCalcData["经营活动现金流出小计"].map(item => Number(end[item])).reduce(((total, cur)=> total + cur), 0).toFixed(2)

    mananetrData[0] = actionTwoField(bizcashinflData[0], bizcashoutfData[0], 'subtract')
    //(Number(bizcashinflData[0]) - Number(bizcashoutfData[0])).toFixed(2)
    mananetrData[1] = actionTwoField(bizcashinflData[1], bizcashoutfData[1], 'subtract')
    //(Number(bizcashinflData[1]) - Number(bizcashoutfData[1])).toFixed(2)

    let invcashinflData = []  //投资活动现金流入小计
    let invcashoutfData = []  //投资活动现金流出小计
    let invnetcashflowData = []   //投资活动产生的现金流量净额

    invcashinflData[0] = fieldsIsNull("投资活动现金流入小计", begin)
    //automaticCalcData["投资活动现金流入小计"].map(item => Number(begin[item])).reduce(((total, cur)=> total + cur), 0).toFixed(2)
    invcashinflData[1] = fieldsIsNull("投资活动现金流入小计", end)
    //automaticCalcData["投资活动现金流入小计"].map(item => Number(end[item])).reduce(((total, cur)=> total + cur), 0).toFixed(2)

    invcashoutfData[0] = fieldsIsNull("投资活动现金流出小计", begin)
    //automaticCalcData["投资活动现金流出小计"].map(item => Number(begin[item])).reduce(((total, cur)=> total + cur), 0).toFixed(2)
    invcashoutfData[1] = fieldsIsNull("投资活动现金流出小计", end)
    //automaticCalcData["投资活动现金流出小计"].map(item => Number(end[item])).reduce(((total, cur)=> total + cur), 0).toFixed(2)

    invnetcashflowData[0] = actionTwoField(invcashinflData[0], invcashoutfData[0], 'subtract')
    //(Number(invcashinflData[0]) - Number(invcashoutfData[0])).toFixed(2)
    invnetcashflowData[1] = actionTwoField(invcashinflData[1], invcashoutfData[1], 'subtract')
    //(Number(invcashinflData[1]) - Number(invcashoutfData[1])).toFixed(2)

    let fincashinflData = []  //筹资活动现金流入小计
    let fincashoutfData = []  //筹资活动现金流出小计
    let finnetcflowData = []   //筹资活动产生的现金流量净额

    fincashinflData[0] = fieldsIsNull("筹资活动现金流入小计", begin)
    //automaticCalcData["筹资活动现金流入小计"].map(item => Number(begin[item])).reduce(((total, cur)=> total + cur), 0).toFixed(2)
    fincashinflData[1] = fieldsIsNull("筹资活动现金流入小计", end)
    //automaticCalcData["筹资活动现金流入小计"].map(item => Number(end[item])).reduce(((total, cur)=> total + cur), 0).toFixed(2)

    fincashoutfData[0] = fieldsIsNull("筹资活动现金流出小计", begin)
    //automaticCalcData["筹资活动现金流出小计"].map(item => Number(begin[item])).reduce(((total, cur)=> total + cur), 0).toFixed(2)
    fincashoutfData[1] = fieldsIsNull("筹资活动现金流出小计", end)
    //automaticCalcData["筹资活动现金流出小计"].map(item => Number(end[item])).reduce(((total, cur)=> total + cur), 0).toFixed(2)

    finnetcflowData[0] = actionTwoField(fincashinflData[0], fincashoutfData[0], 'subtract')
    //(Number(fincashinflData[0]) - Number(fincashoutfData[0])).toFixed(2)
    finnetcflowData[1] = actionTwoField(fincashinflData[1], fincashoutfData[1], 'subtract')
    //(Number(fincashinflData[1]) - Number(fincashoutfData[1])).toFixed(2)

    let cashIncrease = []  //五、现金及现金等价物净增加额
    //chgexchgchgs  汇率变动对现金及现金等价物的影响
    cashIncrease[0] = actionTwoField(
      actionTwoField(mananetrData[0], invnetcashflowData[0], 'add'),
      actionTwoField(finnetcflowData[0], begin["chgexchgchgs"], 'add'),
      'add'
    )
    //(Number(mananetrData[0]) + Number(invnetcashflowData[0]) + Number(finnetcflowData[0]) + Number(begin["chgexchgchgs"])).toFixed(2)
    cashIncrease[1] = actionTwoField(
      actionTwoField(mananetrData[1], invnetcashflowData[1], 'add'),
      actionTwoField(finnetcflowData[1], end["chgexchgchgs"], 'add'),
      'add'
    )
    //(Number(mananetrData[1]) + Number(invnetcashflowData[1]) + Number(finnetcflowData[1]) + Number(end["chgexchgchgs"])).toFixed(2)

    let cashDifference = []   //六、期末现金及现金等价物余额
    cashDifference[0] = actionTwoField(cashIncrease[0], begin["inicashbala"], 'add')
    //(Number(cashIncrease[0]) + Number(begin["inicashbala"])).toFixed(2)
    cashDifference[1] = actionTwoField(cashIncrease[1], end["inicashbala"], 'add')
    //(Number(cashIncrease[1]) + Number(begin["inicashbala"])).toFixed(2)

    let cashnetiData = []   //现金及现金等价物净增加额
    cashnetiData[0] = actionTwoField(
      actionTwoField(begin['cashfinalbala'], begin['cashopenbala'], 'subtract'),
      actionTwoField(begin['equfinalbala'], begin['equopenbala'], 'subtract'),
      'add'
    )
    //(Number(begin['cashfinalbala']) - Number(begin['cashopenbala']) + Number(begin['equfinalbala']) - Number(begin['equopenbala'])).toFixed(2)
    cashnetiData[1] = actionTwoField(
      actionTwoField(end['cashfinalbala'], end['cashopenbala'], 'subtract'),
      actionTwoField(end['equfinalbala'], end['equopenbala'], 'subtract'),
      'add'
    )
    //(Number(end['cashfinalbala']) - Number(end['cashopenbala']) + Number(end['equfinalbala']) - Number(end['equopenbala'])).toFixed(2)
    //"bizcashinfl": "经营活动现金流入小计",
    // "bizcashoutf": "经营活动现金流出小计",
    // "mananetr": "经营活动产生的现金流量净额"

    //"invcashinfl": "投资活动现金流入小计",
    // "invcashoutf": "投资活动现金流出小计",
    // "invnetcashflow": "投资活动产生的现金流量净额"

    //"fincashinfl": "筹资活动现金流入小计",
    // "fincashoutf": "筹资活动现金流出小计",
    // "finnetcflow": "筹资活动产生的现金流量净额"

    newData.map(item => {
      if (item.key === '一、经营活动产生的现金流量') {
        // item.begin = totalCurrentAssets[0]
        // item.end = totalCurrentAssets[1]
        item.children.map(i => {
          if (i.key === 'bizcashinfl') {
            i.begin = bizcashinflData[0]
            i.end = bizcashinflData[1]
          }
          if (i.key === 'bizcashoutf') {
            i.begin = bizcashoutfData[0]
            i.end = bizcashoutfData[1]
          }
          if (i.key === 'mananetr') {
            i.begin = mananetrData[0]
            i.end = mananetrData[1]
          }
        })
      }
      if (item.key === '二、投资活动产生的现金流量') {
        // item.begin = totalCurrentAssets[0]
        // item.end = totalCurrentAssets[1]
        item.children.map(i => {
          if (i.key === 'invcashinfl') {
            i.begin = invcashinflData[0]
            i.end = invcashinflData[1]
          }
          if (i.key === 'invcashoutf') {
            i.begin = invcashoutfData[0]
            i.end = invcashoutfData[1]
          }
          if (i.key === 'invnetcashflow') {
            i.begin = invnetcashflowData[0]
            i.end = invnetcashflowData[1]
          }
        })
      }
      if (item.key === '三、筹资活动产生的现金流') {
        // item.begin = totalCurrentAssets[0]
        // item.end = totalCurrentAssets[1]
        item.children.map(i => {
          if (i.key === 'fincashinfl') {
            i.begin = fincashinflData[0]
            i.end = fincashinflData[1]
          }
          if (i.key === 'fincashoutf') {
            i.begin = fincashoutfData[0]
            i.end = fincashoutfData[1]
          }
          if (i.key === 'finnetcflow') {
            i.begin = finnetcflowData[0]
            i.end = finnetcflowData[1]
          }
        })
      }
      if (item.key === '五、现金及现金等价物净增加额') {
        item.begin = cashIncrease[0]
        item.end = cashIncrease[1]
      }
      if (item.key === '六、期末现金及现金等价物余额') {
        item.begin = cashDifference[0]
        item.end = cashDifference[1]
      }
      if(item.key === '七、现金流量表附表') {
        item.children.map(i => {
          if(i.key === 'cashneti') {
            i.begin = cashnetiData[0]
            i.end = cashnetiData[1]
          }
        })
      }
    })
  }
}