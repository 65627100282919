import React from'react';
import { useSelector } from 'react-redux'
import Style from './index.module.scss';
import SPcompany_logo from '../../../assets/icons/S&P_Global.png';
import PLcompany_logo from '../../../assets/icons/Power_Launch.png';
import wechatQRcode from '@/assets/images/wechatQRcode.jpeg';

const Footer = () => {
  const platform_device = useSelector(state => state.platform_device)
  const showMobile = platform_device=='Mobile'
  return (
    <footer className={Style['footer-container']}>
      { showMobile
          ?<>
            <div>
              <div className={Style.footer_logos}>
                <img src={SPcompany_logo} alt="logo" />
                <img src={PLcompany_logo} alt="logo" />
              </div>
              <div className={Style.footer_contact}>
                <span>电话：130 6786 3083</span>
                <span>邮箱：support@powerlaunch.cn</span>
                <a href='https://beian.miit.gov.cn/#/Integrated/index' target='_blank'>浙ICP备16029739号-4</a>
              </div>
            </div>
            <div className={Style.footer__loggedContent}>
              <img className={Style.footer__loggedContent_qrcode} src={wechatQRcode} alt=''/>
              <div className={Style.footer__loggedContent_scanTips}>
                <div>扫码关注</div>
                <div>标普信评官方微信帐号</div>
              </div>
            </div>
          </>
          :<>
            <span style={{ fontSize: '.75rem', color: 'white'}}>电话：130 6786 3083  |  邮箱：support@powerlaunch.cn  |   <a style={{color: 'white'}} href='https://beian.miit.gov.cn/#/Integrated/index' target='_blank'>浙ICP备16029739号-4</a></span>
            <img src={SPcompany_logo} alt="logo" />
            <img src={PLcompany_logo} alt="logo" />
          </>
      }
    </footer>
  )
}

export default Footer;