import React from 'react'
import { Modal, message } from 'antd'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import { defaultSelectOptions } from './validate'

export const fileLinkToStreamDownload = (url, type) => {
  const index = url.lastIndexOf("/");
  const _baseUrl = url.slice(0, index);
  const fileName = url.substring(index + 1);
  let xhr = new XMLHttpRequest();
  let baseUrl = process.env.NODE_ENV == "development" ? "/pdf" : _baseUrl;
  xhr.open("get", baseUrl + "/" + fileName, true);
  xhr.setRequestHeader("Content-Type", `application/${type}`);
  xhr.responseType = "blob";
  xhr.onload = function () {
    if (this.status === 200) {
      //接受二进制文件流
      var blob = this.response;
      downloadExportFile(blob, fileName);
    }
  };
  xhr.send();
};
const downloadExportFile = (blob, tagFileName) => {
  let downloadElement = document.createElement("a");
  let href = blob;
  if (typeof blob == "string") {
    downloadElement.target = "_blank";
  } else {
    href = window.URL.createObjectURL(blob); //创建下载的链接
  }
  downloadElement.href = href;
  downloadElement.download = decodeURIComponent(tagFileName);
  document.body.appendChild(downloadElement);
  downloadElement.click(); //点击下载
  document.body.removeChild(downloadElement); //下载完成移除元素
  if (typeof blob != "string") {
    window.URL.revokeObjectURL(href); //释放掉blob对象
  }
};

//
export const getUrlParameter = (sParam, from) => {
  let i;
  let sPageURL;
  let sParameterName;
  let sURLVariables;
  let url = window.location.href;
  if (from === "hash") {
    url = window.location.hash;
  }
  // alert(url)
  const newurl = url.substring(url.indexOf("?"));
  // sPageURL = decodeURIComponent(newurl.substring(1));
  sPageURL = newurl.substring(1);
  sURLVariables = sPageURL.split("&");
  sParameterName = void 0;
  i = void 0;
  i = 0;
  while (i < sURLVariables.length) {
    sParameterName = sURLVariables[i].split("=");
    if (sParameterName[0] === sParam) {
      if (sParameterName[1] === void 0) {
        return true;
      }
      return decodeURIComponent(sParameterName[1]);
    }
    i++;
  }
};

export const deepCopy = (obj) => {
  if (obj === null || typeof obj !== 'object') {
      return obj;
  }

  if (Array.isArray(obj)) {
      const arrCopy = [];
      for (let i = 0; i < obj.length; i++) {
          arrCopy[i] = deepCopy(obj[i]);
      }
      return arrCopy;
  }

  const objCopy = {};
  for (const key in obj) {
      if (obj.hasOwnProperty(key)) {
          objCopy[key] = deepCopy(obj[key]);
      }
  }
  return objCopy;
}

const { confirm } = Modal

/**
 * @description: 是否为对象
 * @param {*} value
 * @return {*}
 */
export const isObject = (value) => {
  return Object.prototype.toString.call(value)==="[object Object]"
}
/**
 * @description: 是否为数组
 * @param {*} value
 * @return {*}
 */
export const isArray = (value) => {
  return Object.prototype.toString.call(value)==="[object Array]"
}
/**
 * @description: 拼接参数为URL地址格式
 * @param {*} params 携参值 【Object：键值对】
 * @return {*}
 */
export const setParamsUrl = (params = null) => {
  let paramsPath = ''
  params && Object.keys(params).map(key => paramsPath += `&${key}=${params?.[key]??''}` )
  paramsPath = paramsPath?'?'+paramsPath.substring(1):''
  return paramsPath
}
/**
 * @description: 跳转新页面显示详情
 * @param {*} params  跳转携参值 【Object：键值对】
 * @param {*} path  跳转路径
 * @return {*}
 */
export const skipDetail = ({params = null, path}) => {
  let paramsPath = setParamsUrl(params)
  window.open(path+paramsPath, '_blank')
}
/**
 * @description: 存储变量--下拉列表/联动筛选/...
 * @param {*}
 * @return {*}
 */
export const modulesConfig = { ...defaultSelectOptions }
/**
 * @description: 获取变量值
 * @param {*} key 存储变量对应的key值
 * @param {*} parames 存储变量对应的value值
 * @return {*}
 */
export const getModulesConfig = (key, parames) => {
  modulesConfig[key] = parames
}
/**
 * @description: 获取变量值
 * @param {*} key 存储变量对应的key值
 * @param {*} parames 存储变量对应的value值
 * @param {*} type 更新/删除 update/delete
 * @return {*}
 */
export const setModulesConfig = (key, parames, type='update') => {
  if(type=='update') modulesConfig[key] = parames
    else if(type=='delete') delete modulesConfig[key]
}
/**
 * @description: 确认框提醒
 * @param {*} title 确认框标题
 * @param {*} content 确认框内容
 * @param {*} onOk 确认框确认方法
 * @param {*} onCancel 确认框取消方法
 * @param {*} icon 确认框icon
 * @return {*}
 */
export const confirmModal = ({title, content, onOk, onCancel, okText= "确定",
  cancelText= "取消", icon=<ExclamationCircleOutlined />}) => {
  confirm({
    title, icon, content, okText, cancelText,
    onOk, onCancel
  })
}
/**
 * @description: 提交成功信息
 * @param {*} msg 操作状态
 * @return {*}
 */
export const successMessage = (msg) => {
  message.success(`${msg}申请提交成功，可前往数据提审处查看提交状态`)
}
/**
 * @description: 比较对象中属性是否有变动
 * @param {*} node_a 对象A
 * @param {*} node_b 对象B
 * @return {Boolean} 是否有变动
 */
export const isObjectValueEqual = (node_a, node_b) => {
  const aProps = Object.getOwnPropertyNames(node_a)
  const bProps = Object.getOwnPropertyNames(node_b)
  if (aProps.length !== bProps.length) {
    return false
  }
  for (let i = 0; i < aProps.length; i++) {
    const propName = aProps[i]
    if (node_a[propName] !== node_b[propName]) {
      return false
    }
  }
  return true
}
/**
 * 返回图表的 dataURL 用于生成图片。
 * @param {*} chart 需要获取 DataURL 的 chart 实例
 * @returns {String} 返回图表的 dataURL
 */
 export const chartToDataURL = (chart) => {
  const canvas = chart.getCanvas()
  const renderer = chart.renderer
  const canvasDom = canvas.get('el')
  let dataURL = ''
  if (renderer === 'svg') {
    const clone = canvasDom.cloneNode(true)
    const svgDocType = document.implementation.createDocumentType(
      'svg',
      '-//W3C//DTD SVG 1.1//EN',
      'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'
    )
    const svgDoc = document.implementation.createDocument('http://www.w3.org/2000/svg', 'svg', svgDocType)
    svgDoc.replaceChild(clone, svgDoc.documentElement)
    const svgData = new XMLSerializer().serializeToString(svgDoc)
    dataURL = 'data:image/svg+xml;charset=utf8,' + encodeURIComponent(svgData)
  } else if (renderer === 'canvas') {
    dataURL = canvasDom.toDataURL('image/png')
  }
  return dataURL
}
/**
 * @description: 判断是否为闰年的函数
 * @param {*} year
 * @return {*}
 */
export const isRunyear = (year) => {          
  let flag = false
  if((year%4==0 && year%100!=0) || year%400==0) flag = true
  return flag
}
/**
 * @description: 判断是否为闰年的函数
 * @param {*} auditObj 差异数据 { *: { old: *, new: * }} }
 * @return {*}
 */
 export const auditData = (objParam, moudles) => {          
  let oldData = {}, _audit = objParam?.auditEdit?JSON.parse(objParam.auditEdit)[moudles]:{}
  Object.keys(_audit).map(key => {
    if(_audit[key]?.new) oldData[key] = _audit[key]?.old??_audit[`${key}Name`]?.old??' '
    return true
  })
  return { old: oldData, now: objParam }
}
/**
 * @description: 递归遍历重新排列值
 * @param {*} arr 遍历数组
 * @param {*} variables 得到的字段集合
 * @return {_value} 数据集合
 */
export const recursionArray_Tree = (arrOrigin) => {
  let _valueArray = []
  const recursion = (_arrO) => {
    let _arr = []
    _arrO.map(item => {
      let _obj = {}
      _obj.value = `${item.value}-${item.level}`
      _obj.title = item.title
      if(item.children && item.children.length>0) _obj.children = recursion(item.children)
        else delete item.children
      _arr.push(_obj)
    })
    return _arr
  }
  _valueArray = recursion(arrOrigin)
  return _valueArray
}
/**
 * @description: 递归遍历对比求值
 * @param {*} arrOrigin 遍历数组
 * @param {*} arrDiffValue 遍历源数组
 * @param {*} variables 对比字段
 * @param {*} reValue 得到的字段集合
 * @return {_value} 数据集合
 */
export const recursionArrayDiff = (arrOrigin, arrDiffValue, variables, reValue) => {
  let _valueArray = [], diffI = 0
  const recursion = (_arrO, _arrD) => {
    _arrO.map(parents => {
      if(parents[variables] == _arrD) {
        _valueArray.push(parents[reValue])
        if(parents.children && parents.children.length>0) {
          diffI+=1
          recursion(parents.children, arrDiffValue[diffI])
        }
      }
      return true
    })
  }
  recursion(arrOrigin, arrDiffValue[diffI])
  return _valueArray
}
/**
 * @description: 查询条件-地域&行业
 * @param {*} obj 【Object】查询条件源数据
 * @param {*} variables 【String】对比字段
 * @param {*} reValue 【Array】查询条件返回条件集合
 * @return {obj} 更改后的对象
 */
export const resetSearch_industryArea = (obj, variables, reValue) => {
  if(obj?.[variables]?.length>0){
    let reKeyList = {}
    reValue.map(reKey => reKeyList[reKey] = [] )
    obj[variables].map(item => {
      item.level=='1' && reKeyList[reValue[0]].push(Number(item.id))
      item.level=='2' && reKeyList[reValue[1]].push(Number(item.id))
      item.level=='3' && reKeyList[reValue[2]].push(Number(item.id))
    })
    obj[reValue[0]] = reKeyList[reValue[0]]
    obj[reValue[1]] = reKeyList[reValue[1]]
    obj[reValue[2]] = reKeyList[reValue[2]]
    obj[variables] = undefined
  } else obj[variables] = obj[reValue[0]] = obj[reValue[1]] = obj[reValue[2]] = undefined
}
/**
 * @description: 查询条件-时间区间
 * @param {*} obj 【Object】查询条件源数据
 * @param {*} variables 【String】对比字段
 * @param {*} reValue 【Array】查询条件返回条件集合
 * @return {*}
 */
export const resetSearch_time = (obj, variables, reValue) => {
  if(obj?.[variables]?.length>0) {
    obj[reValue[0]] = obj?.[variables][0]
    obj[reValue[1]] = obj?.[variables][1]
    obj[variables] = undefined
  } else obj[variables] = obj[reValue[0]] = obj[reValue[1]] = undefined
}
/**
 * @description: 指定截取小数点位数
 * @param {*} value 需要截取的数值
 * @param {*} decimal 需要截取的小数位数
 * @return {*}
 */
export const toFixedUnit = (value, decimal) => {
  if(!value) return 0
	value = value.toString()
	let index = value.indexOf('.')
	if (index !== -1) value = value.substring(0, decimal + index + 1)
		else value = value.substring(0)
	return Number(parseFloat(value).toFixed(decimal))
}
/**
 * @description: 获取当前选中数据
 * @param {*} options 【String: 当前的列表项名】
 * @param {*} value 【Number/String: 当前选中的值】
 * @param {*} variable 【String: 与之需对比的字段 *默认value】
 */ 
export const getCurrentOptionItem = (options, value, variable='value') => {
  return (modulesConfig?.[options]??[]).filter(_f=> _f[variable]==value)[0]
}
/**
 * @description: 防抖函数
 * @param {*} func
 * @param {*} delay
 * @return {*}
 */
export const debounceEvent = (func, delay) => {
  let timeout
  return function (...args) {
    const context = this
    clearTimeout(timeout)
    timeout = setTimeout(() => func.apply(context, args), delay);
  };
}
/**
 * @description: 判断是否为空
 * @param {*} value
 * @return {*}
 */
export const isNull = (value) => {
  if(isArray(value) && value.length==0) return true
  return [undefined, null, ''].includes(value)
}
/**
 * @description: 检测设备
 * @return {*}
 */
export const getDeviceType = () => {
  // const ua = navigator.userAgent;
  const width = window.innerWidth;

  // if (/android/i.test(ua)) {
  //   return "Android";
  // }
  // if (/iPad|iPhone|iPod/.test(ua) && !window.MSStream) {
  //   return "iOS";
  // }

  if (width <= 766) {
    return "Mobile";
  } else if (width <= 1024) {
    return "Tablet";
  } else {
    return "Desktop";
  }
}
/*
 * @description: 数组去重
 * @param {*} arr 【Array: 去重的数组】
 * @return {*}
 */
export const removeDuplicates = (arr) => {
  return [...new Set(arr)]
}
