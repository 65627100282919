const modulesFiles = require.context('./modules', true, /\.js$/)

let config = {}

const modules = modulesFiles.keys().reduce((modules, modulePath) => {
  const value = modulesFiles(modulePath)
  config = Object.assign(config,value.default)
  
},{})

export default config