import service from "@/utils/request"

const user = {
  // 查看是否存在当前账户 (忘记密码验证)
  checkUserInfo(params) {
    return service.request({ url: "/user/checkUserInfo", method: "get", params })
  },
  // 重设用户密码
  resetUserPwd(data) {
    return service.request({ url: "/user/forgotPasswordAndSendEmail", method: "post", data })
  },
  // 重设用户密码发送邮箱验证码
  sendEmailByForgot(params) {
    return service.request({ url: "/user/sendEmailByAccount", method: "get", params })
  },
  // 获取用户列表
  getUserList(data) {
    return service.request({ url: "/user/userList", method: "post", data })
  },
  // 获取用户信息
  getUserInfo() {
    return service.request({ url: "/user/getUserInfo", method: "get" })
  },
  // 编辑用户信息
  updateUserInfo(data) {
    return service.request({ url: "/user/editUser", method: "post", data })
  },
  // 创建用户信息
  createUserInfo(data) {
    return service.request({ url: "/user/addUser", method: "post", data })
  },
  // 删除用户信息
  deleteUserInfo(params) {
    return service.request({ url: "/user/delUser", method: "get", params })
  },
  // 启用用户
  enableUserStatus(params) {
    return service.request({ url: "/user/changeEnable", method: "get", params })
  },
  // 用户权限列表
  getUserPermissionList(data) {
    return service.request({ url: "/permission/getUserPermissionDataList", method: "post", data })
  },
  // 更新用户权限有效期
  updateUserPermissionDate(data) {
    return service.request({ url: "/permission/updateDataLastTime", method: "post", data })
  },
  // 添加用户权限
  addUserPermissionDate(data) {
    return service.request({ url: "/permission/addPermissions", method: "post", data })
  },
  // 删除用户权限
  deleteUserPermissionDate(data) {
    return service.request({ url: "/permission/delPermission", method: "post", paramsConfig:{data:JSON.stringify(data)} })
  },
}

export default user;