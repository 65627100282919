import React, { useState } from 'react';
import { Radio } from "antd"
import Style from './SubjectCreditBarChart.module.scss'
import ReactEcharts from 'echarts-for-react'
import { useSearchParams } from 'react-router-dom'
import CustomModal from '../../../components/CustomModal'
import Tip from '../../../assets/icons/tip.png'

export default function SubjectCreditBarChart(props) {
  const {basicData} = props
  const [searchParams] = useSearchParams(); // 获取查询参数
  const id = searchParams.get("id"); // 获取名为 'url' 的查询参数
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [radioActive, setRadioActive] = useState("default");

  const xAxisData = ["aaa1", "aaa2", "aaa3", "aaa4", "aaa5", "aa+1", "aa+2", "aa", "aa-", "a+", "a", "a-", "bbb+", "bbb", "bbb-", "bb+", "bb", "bb-", "b+", "b", "b-", "ccc"]
  const getChartOptions = () => {
    let options = {
      title: {
        text: '约 4500 家样本企业潜在主体信用质量分布',
        left: "center",
        bottom: 0
      },
      tooltip: {},
      legend: {
        show: true
      },
      grid: {
        left: "50px", right: "50px"
      },
      xAxis: {
        data: xAxisData,
      },
      yAxis: {
        type: "value",
        name: '企业数量',
        nameLocation: 'end',
        nameGap: 30
      },
      series: getSeries()
    }
    return options;
  }
  const getSeries = () => {
    const baseData = [
      {
        type: "bar",
        data: [117, 159, 180, 73, 39, 273, 182, 451, 371, 388, 385, 386, 454, 327, 217, 101, 95, 57, 27, 14, 102, 104],
      },
    ]
    const findIndex = xAxisData.findIndex(d=> d==basicData?.doc?.icrSub)
    if(findIndex!==-1){
      baseData[0].data[findIndex] = {value: baseData[0].data[findIndex], itemStyle: {color: " #33D695"}}
    }
    return baseData
  }
  const radioChange = (value) => {
    if (value === "large") {
      setIsModalOpen(true)
      setRadioActive("large")

    } else {
      setRadioActive("default")

    }
  }
  const handleCancel = () => {
    setIsModalOpen(false);
    setRadioActive("default")
  };
  const handleOk = () => {
    handleCancel()
  };

  return (
    <div className={Style['subjectCreditBarChart']}>
      <div className={Style['head']}>
        <div style={{display:"flex",alignItems:"center"}}>
          <div>
            约 4500 家样本企业潜在主体信用质量
          </div>&nbsp;&nbsp;&nbsp;&nbsp;
          <div className={Style["beizhu"]}>
            <img src={Tip} alt="" />
            <div>（绿色区域为本企业所处评分区间）</div>
          </div>
        </div>
        <div style={{ marginRight: "24px" }}>
          <Radio.Group defaultValue={"default"} value={radioActive} buttonStyle="solid" onChange={(e) => radioChange(e.target.value)}>
            <Radio.Button value="large">本行业</Radio.Button>
            <Radio.Button value="default">全行业</Radio.Button>
          </Radio.Group>
        </div>
      </div>
      <ReactEcharts
        option={getChartOptions()}      // option：图表配置项
        notMerge={true}
        lazyUpdate={true}
        style={{ height: '400px' }}
      />
      {isModalOpen &&
        <CustomModal handleCancel={handleCancel} handleOk={handleOk} isModalOpen={isModalOpen} wechatQRCode={true} />
      }
    </div>
  )
}
