import React from'react';
import Style from './pdfPreview.module.scss';
import PdfContainer from '../../../components/PDFLoader';

 const PdfPreview = (props) => {
  return (
    <div className={Style['pdf-view']}>
      <div className={Style['queryView']}>
        <PdfContainer url={props.reportLink} margin={{left: 30, right: 30}} />
      </div>
    </div>
  )
}

export default PdfPreview;