import ApiConstants from "../constants/apiConstants";
import request from "../utils/request";
import { message } from "antd";

export const getCompanyList = (params, callback) => async (dispatch) => {
  try {
    const result = await request({
      method: "POST",
      url: ApiConstants.HOST_NAME_COMPANY_LIST,
      data: params,
      headers: { "Content-Type": "application/json;charset=UTF-8" },
    });
    if (result.data && result.code === 200) {
      if (callback) callback(result.data);
    } else {
      message.error(result.data.msg, 2);
    }
  } catch (e) {
    console.log(e);
  }
};
export const getIndustryList = (params, callback) => async (dispatch) => {
  try {
    const result = await request({
      method: "GET",
      url: ApiConstants.HOST_NAME_COMPANY_GET_INDUSTRY_LIST,
      params,
    });
    if (result.data && result.code === 200) {
      if (callback) callback(result.data);
    } else {
      message.error(result.data.msg, 2);
    }
  } catch (e) {
    console.log(e);
  }
};

export const getTypeList = (params, callback) => async (dispatch) => {
  try {
    const result = await request({
      method: "GET",
      url: ApiConstants.HOST_NAME_COMPANY_GET_TYPE_LIST,
      params,
    });
    if (result.data && result.code === 200) {
      if (callback) callback(result.data);
    } else {
      message.error(result.data.msg, 2);
    }
  } catch (e) {
    console.log(e);
  }
};

export const getCompanyFinance = (params, callback) => async (dispatch) => {
  try {
    const result = await request({
      method: "GET",
      url: ApiConstants.HOST_NAME_COMPANY_FINANCE,
      params,
    });
    if (result.data && result.code === 200) {
      if (callback) callback(result.data);
    } else {
      message.error(result.data.msg, 2);
    }
  } catch (e) {
    console.log(e);
  }
};
