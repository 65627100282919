const excludedDomains = ['163.com','qq.com','gmail.com','outlook.com','hotmail.com','yahoo.com','icloud.com','protonmail.com','mail.com','126.com','sohu.com','shxsj.com','lhratings.com','189.com']
let domainPattern = excludedDomains.map(domain => domain.replace('.', '\\.')).join('|')
export const validate_password = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{6,16}$/
export const validate_email = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
export const validate_mobile = /^(?:(?:\+|00)86)?1(?:(?:3[\d])|(?:4[5-79])|(?:5[0-35-9])|(?:6[5-7])|(?:7[0-8])|(?:8[\d])|(?:9[1589]))\d{8}$/
export const excludeEmailReg = new RegExp(`^[a-zA-Z0-9._%+-]+@(?!${domainPattern}$)[a-z0-9.-]+\\.[a-z]{2,}$`);

/**
 * @description: 列表默认初始数据
 * @param {*}
 * @return {*}
 */
export const tableDefaultConfig = { current: 1, size: 10 };
/**
 * @description: 列表默认初始数据
 * @param {*}
 * @return {*}
 */
export const selectOptionsCode = [
  // { code: 接口请求code参数, name: 列表对照类型, options: 列表对照参数 },
];

/**
 * @description: 列表默认初始数据
 * @param {*}
 * @return {*}
 */
export const defaultSelectOptions = {
  // industryOptions  标普行业
  // companyTypeOptions  标普企业类型
  userLavel: [
    { label: '试用账号', value: 1, id: '01' },
    { label: '正式用户', value: 2, id: '02' },
    { label: '管理员', value: 3, id: '03' },
  ],
  emailAuthStatus: [
    { label: '是', value: true, id: '00' },
    { label: '否', value: false, id: '01' },
  ],
  pcaLevel: [
    { label: 'aaa1', value: 'aaa1', id: '01' },
    { label: 'aaa2', value: 'aaa2', id: '02' },
    { label: 'aaa3', value: 'aaa3', id: '03' },
    { label: 'aaa4', value: 'aaa4', id: '04' },
    { label: 'aaa5', value: 'aaa5', id: '05' },
    { label: 'aa+1', value: 'aa+1', id: '06' },
    { label: 'aa+2', value: 'aa+2', id: '07' },
    { label: 'aa', value: 'aa', id: '08' },
    { label: 'aa-', value: 'aa-', id: '09' },
    { label: 'a+', value: 'a+', id: '10' },
    { label: 'a', value: 'a', id: '11' },
    { label: 'a-', value: 'a-', id: '12' },
    { label: 'bbb+', value: 'bbb+', id: '13' },
    { label: 'bbb', value: 'bbb', id: '14' },
    { label: 'bbb-', value: 'bbb-', id: '15' },
    { label: 'bb+', value: 'bb+', id: '16' },
    { label: 'bb', value: 'bb', id: '17' },
    { label: 'bb-', value: 'bb-', id: '18' },
    { label: 'b+', value: 'b+', id: '19' },
    { label: 'b', value: 'b', id: '20' },
    { label: 'b-', value: 'b-', id: '21' },
    { label: 'ccc', value: 'ccc', id: '22' }
  ],
  userResource: [
    { label: 'DM舆情板块', value: 'por1', id: '00' },
    { label: 'DM评级墙', value: '1', id: '01' },
    { label: '标普-市场日常工作客户注册渠道', value: 'mkt1', id: '02' },
    { label: '标普-公众号注册渠道', value: '3', id: '03' },
    { label: '管理后台创建', value: '4', id: '04' },
    { label: '系统自然流量注册', value: '0', id: '05' },
  ]
}
